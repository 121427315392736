import {
  TPath,
  isElement,
  TReactEditor,
  removeNodes,
  TElement,
  findNodePath,
  unwrapNodes,
} from "@udecode/plate";
import { BaseEditor, Editor } from "slate";

import { MySceneElement } from "../../../config/typescript";
import { ELEMENT_SCENE } from "../createScenePlugin";

export const removeOrnamentalBreak = (
  element: TElement,
  editor: TReactEditor
): void => {
  const path = findNodePath(editor, element);
  removeNodes(editor, { at: path });

  Editor.withoutNormalizing(editor as BaseEditor, () => {
    const getNextSceneNode = (editor: TReactEditor, start: TPath): void => {
      const nextSceneNode = Editor.next<MySceneElement>(editor as BaseEditor, {
        at: start,
        match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
      });
      if (nextSceneNode) {
        unwrapNodes(editor, {
          at: nextSceneNode[1],
          match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
        });
        getNextSceneNode(editor, nextSceneNode[1]);
      }
    };

    const firstNodeInDoc = Editor.first(editor as BaseEditor, []);
    getNextSceneNode(editor, firstNodeInDoc[1]);
  });
};
