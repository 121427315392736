import { NodeEntry } from "slate";
import { TPath } from "@udecode/plate";

import { MyOrnamentalBreakElement, MySceneElement } from "../../components/Plate/config/typescript";

/**
 * for placeholders   droppableId -> {parentChapterId}-{sceneIndex}-placeholder-{index}
 * for scenes   droppableId -> {chapter Id}-{sceneIndex}
 * for chapters droppableId -> {chapter Id}
 *
 * @param droppableId
 * @summary parse the droppable id and returns
 * @returns [chapterId,sceneIndex]
 */

export const decodeDroppableId = (
  droppableId: string
): [string, number | undefined, string | undefined] => {
  const [chapterId, sceneIndex, type] = droppableId.split("-");
  const scene =
    sceneIndex === undefined || sceneIndex === ""
      ? undefined
      : parseInt(sceneIndex);
  return [chapterId, scene, type];
};

/**
 * Get the destination start path to drop scene and ob nodes in the dest chapter when
 * drag and drop scenes between two chapters
 */
export const getDropDestinationPath = (
  destSceneEntry?: NodeEntry<MySceneElement>,
  destSceneObSiblings?: {
    previous: NodeEntry<MyOrnamentalBreakElement> | undefined;
    next: NodeEntry<MyOrnamentalBreakElement> | undefined;
  }
): TPath => {
  /** if the destination points to the first node of the chapter */
  let dropPath = [0];
  if(destSceneEntry){
    if(destSceneObSiblings?.next){
      /** 
       * if there's an ob sibling after the dest scene entry 
       *  - dest scene is not the last scene in the chapter
       *  - the src content should be dropped after the next ob sibling
       **/
      dropPath = destSceneObSiblings.next[1];
    }else{
      /**
       * if there's no ob sibling after the dest scene entry
       *  - dest scene is the last scene in the chapter
       *  - the src content should be dropped after the dest scene entry
       */
      dropPath = destSceneEntry[1];
    }
  }
  const destParentPath = dropPath.slice(0, -1);
  const destOffset = dropPath[dropPath.length - 1] + 1;
  return [...destParentPath, destOffset];
};
